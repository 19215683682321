import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // React Router Link ekliyoruz
import styles from './passwordSuccess.module.css';
import logo from './confirmm.png';
import returnButtonImage from './ep_back.png'; // Resim dosyasının yolunu belirtin

const PasswordSuccess = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Şifre sıfırlama işlemi burada yapılacak
    console.log('Şifre sıfırlama isteği gönderildi:', email);
  };

  return (
    <div className={styles.container}>
      <img src={logo} alt="Logo" className={styles.logo} />
      <h1 className={styles.title}>Password Reset!</h1>
      <p className={styles.subtitle}>Your password has been successfully reset<br />  click below to continue your access</p>
      <button className={styles.button}>
        <Link to="/signin">  Continue</Link>
        </button>

      <div className={styles.return}>
        <Link to="/signin" className={styles.returnButton}>
          <img src={returnButtonImage} alt="Return" className={styles.returnButtonImage} />
          Return to the signin screen
        </Link>
      </div>
      <p className={styles.Privacy}>Terms of Use | Privacy Policy</p>
    </div>
  );
};

export default PasswordSuccess;
