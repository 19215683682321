import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // React Router Link ekliyoruz
import styles from './forgot.module.css';
import logo from './lockicon.png';
import returnButtonImage from './ep_back.png'; // Resim dosyasının yolunu belirtin
import axios from 'axios';

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  
  const sendPasswordResetEmail = async (email) => {
    try {
      console.log('Email:', email);
      const response = await axios.post('https://nodejsapp-ow9m.onrender.com/api/forgotPassword', { email });
      console.log("yanıt:",response)
      if (!response.data.message) {
        throw new Error('Şifre sıfırlama isteği başarısız oldu.');
      }
  
      console.log(response.data.message);
      setMessage(response.data.message);
    } catch (error) {
      console.error('Şifre sıfırlama isteği gönderilirken bir hata oluştu:', error.message);
      setMessage('Şifre sıfırlama isteği gönderilirken bir hata oluştu.');
    }
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // Şifre sıfırlama işlemi burada yapılacak
    sendPasswordResetEmail(email)
  };

  return (
    <div className={styles.container}>
  <img src={logo} alt="Lock Icon" className={styles.lockIcon}></img>
      <h1 className={styles.title}>Forgot your password?</h1>
      <p className={styles.subtitle}>Enter your email to reset it</p>
        <label htmlFor="email" className={styles.label}>
          Email Address
        </label>
        <input
          type="email"
          id="email"
          className={styles.input}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder='Enter your email...'
          style={{ fontSize: '12px' }}
        />
        <button type="submit" className={styles.button} >Confirm</button>
      {message && <p>{message}</p>} {/* Mesaj varsa ekrana yazdır */}
      <div className={styles.return}>
        <Link to="/signin" className={styles.returnButton}>
          <img src={returnButtonImage} alt="Return" className={styles.returnButtonImage} />
          Return to the signin screen
        </Link>
      </div>
      <p className={styles.Privacy}>Terms of Use | Privacy Policy</p>
    </div>
  );
};

export default Forgot;
