import React, { useState, useEffect } from 'react';
import styles from './Notification.module.css';

const Notification = ({ message, type, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 5000); // 5 saniye sonra bildirimi gizle
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`${styles.notification} ${type}`}>
      <p>{message}</p>
    </div>
  );
};

export default Notification;
