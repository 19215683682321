import React,{useState} from 'react';
import styles from './Signup.module.css';
import googleIcon from './icons8-google-48.png'; // Google simgesi
import appleIcon from './icons8-apple-50.png'; // Apple simgesi
import axios from 'axios';

const clientId = '1090885463236-pmeeedg1oaedm8mki6bsr5hsks06uf7v.apps.googleusercontent.com'; // Google OAuth Client ID'nizi buraya ekleyin


const Message = ({ type, children, onClose }) => {
  const messageContainerStyle = {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    zIndex: '999',
  };

  const messageStyle = {
    backgroundColor: type === 'success' ? '#4caf50' : '#f44336',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
    display: 'flex',
    alignItems: 'center',
  };

  const closeIconStyle = {
    marginLeft: '10px',
    cursor: 'pointer',
  };

  return (
    <div style={messageContainerStyle}>
      <div style={messageStyle}>
        {children}
        <span style={closeIconStyle} onClick={onClose}>❌</span>
      </div>
    </div>
  );
};
const Signup = () => {
  // State'ler kullanıcı bilgilerini tutacak
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(''); // Yeni eklenen state

  // Form gönderildiğinde bu fonksiyon çalışacak
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Kullanıcı bilgilerini içeren bir obje oluştur
      const userData = {
        fullName,
        email,
        password
      };

      // Backend'e POST isteği yap
      const response = await axios.post('https://nodejsapp-ow9m.onrender.com/api/signup', userData);
      console.log(response);
      
      if (response.status === 201) {
        setMessage(response.data.message);        // Başarı durumunda yönlendirme veya diğer işlemler burada yapılabilir
      } else {
        setMessage(response.data.message);
        // Hata durumunda kullanıcıya bilgi verilebilir
      }
    } catch (error) {
      console.error('Bir hata oluştu:', error);
    }
  };

  const handleCloseMessage = () => {
    setMessage('');
  };
  


  const signupWithGoogle = ()=>{
    window.open("https://nodejsapp-ow9m.onrender.com/auth/google/callback","_self")
}
  
  return (
    <div className={styles.container}>
        <h2>Sign up to Passific House</h2>
        <p className={styles.inputLabel}>Full name</p>
        <input
          className={styles.inputs}
          type="text"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
        <p className={styles.inputLabel}>Email address</p>
        <input
          className={styles.inputs}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <div className={styles.passwordLabelContainer}>
          <p className={styles.passwordLabel}>Password </p>
        </div>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className={styles.Passwordinput}

        />
        <button type="submit" className={styles.signinButton} onClick={handleSubmit}>
          Sign up
        </button>
        <p className={styles.SubText}>
          Already have an account?{' '}
          <a className={styles.SignUplink} href="/signin">
            Sign In
          </a>
          <div className={styles.orContainer}>
          <div className={styles.orLine}></div>
          <div className={styles.orText}>or</div>
          <div className={styles.orLine}></div>
        </div>
        </p>

        {/* Google ve Apple butonları */}
        <div className={styles.socialButtons}>
        <button className={styles.googleButton} onClick={signupWithGoogle}>
      <img src={googleIcon} alt="Google" className={styles.socialIcon} />
      Continue with Google
    </button>
          
        </div>

        <p className={styles.Privacy}>Terms of Use | Privacy Policy</p>
      {message && <Message type={message.includes('başarıyla') ? 'success' : 'error'} onClose={handleCloseMessage}>{message}</Message>}
      <p className={styles.Privacy}>Terms of Use | Privacy Policy</p>

    </div>
  );
};

export default Signup;
