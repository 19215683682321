// NotFound.jsx

import React from 'react';
import logo from './icons8-forbidden-50.png';
import styles from './unauthorized.module.css';
import { Link } from 'react-router-dom'; // React Router Link ekliyoruz

const UnAuthorized = () => {

     const color1 = {
        color: '#00b49c'
      };
  return (
    <div className={styles.notFoundContainer}>
      <img src={logo} alt="Logo" className={styles.logo} />
      <h1 className={styles.errorMessage2}>This page is restricted. <br></br>Please <span style={color1}>Sign in</span> or <span style={color1}>Sign up</span> to access.</h1>
      <div className={styles.buttonGroup}>
            <button type="submit" className={styles.signinButton} ><Link to="/signin">Signin</Link></button>
            <button type="button" className={styles.signupButton} ><Link to="/signup">Signup</Link></button>
          </div>
     
    </div>
  );
};

export default UnAuthorized;
