import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Signin from './components/Signin';
import Signup from './components/Signup';
import Home from './components/Home';
import Chat from './components/Chat';
import ChatPage from './components/ChatPage';
import Forgot from './components/forgot';
import SetPassword from './components/setpassword';
import PasswordSuccess from './components/passwordSuccess';
import NotFound from './components/NotFound';
import Unauthorized from './components/unauthorized'; // Bileşen adını düzelt

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Oturum durumu
  const [user, setUser] = useState(null); // Kullanıcı bilgilerini saklamak için state

// Kullanıcı bilgilerini set etmek için bir fonksiyon
  const setUserDetails = (userData) => {
    setUser(userData);
  };
  return (
    <Router>
      <Routes>
        {/* Herkese açık sayfalar */}
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        {/* Kullanıcı oturum açtığında gösterilecek sayfalar */}
        <Route path="/chat" element={<Chat />} />
        <Route
          path="/signin"
          element={<Signin setIsLoggedIn={setIsLoggedIn} setUserDetails={setUserDetails} />}
        />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        {/* Oturum açmış kullanıcının erişebileceği sayfalar */}
        {isLoggedIn ? (
          <>
           
            <Route path="/passwordSuccess" element={<PasswordSuccess />} />
            <Route path="/chatpage" element={<ChatPage user={user} setUser={setUserDetails} />} />


          </>
        ) : (
          // Oturum açmamış kullanıcının erişebileceği sayfalar
          <>
           <Route path="/chat" element={<Navigate to="/unauthorized" />} />
          <Route path="/chatpage" element={<Navigate to="/unauthorized" />} />

          </>
        )}
        {/* SetPassword sayfası sonrası success sayfası */}
        <Route path="/setPassword/:resetToken" element={<SetPassword />} />
        <Route path="/setPassword" element={<SetPassword />} />
        {/* Oturum açmamış kullanıcının erişim izni olmayan diğer sayfalara yönlendirilmesi */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
