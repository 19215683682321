// NotFound.jsx

import React from 'react';
import logo from './sad.png';
import styles from './NotFound.module.css';
import { Link } from 'react-router-dom'; // React Router Link ekliyoruz

const NotFound = () => {
  return (
    <div className={styles.notFoundContainer}>
      <img src={logo} alt="Logo" className={styles.logo} />
      <p className={styles.errorMessage1}>404 Error</p>
      <h1 className={styles.errorMessage2}>Page not found</h1>
      <p className={styles.errorMessage3}>Sorry, the page you are looking for could not be found or has been removed.</p>
      <button className={styles.notfounButton}> <Link to="/">Take me back to Home page</Link></button>
     
    </div>
  );
};

export default NotFound;
