import React, { useState } from 'react';
import axios from 'axios';
import styles from './FeedbackModal.module.css';

const FeedbackModal = ({ isOpen, onClose }) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://nodejsapp-ow9m.onrender.com/api/feedback', {
        fullName,
        email,
        feedback
      });

      console.log(response.data.message);

      setFeedbackSent(true);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };
  
  const onCloseModal = () => {
    // Modal kapatıldığında input değerlerini sıfırla ve feedback sent state'ini false yap
    setFullName('');
    setEmail('');
    setFeedback('');
    setFeedbackSent(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.closeIcon} onClick={onCloseModal}>×</div>
        
        <form onSubmit={handleSubmit} className={styles.Feedbackform}>
          <div className={styles.formGroup}>
            <h2 className={styles.Title}>Give Feedback</h2>
            <label htmlFor="fullname" className={styles.allLabels}>Full Name</label>
            <input type="text" id="fullname" value={fullName} className={styles.fullnameInput} onChange={(e) => setFullName(e.target.value)} />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email" className={styles.allLabels}>Email address</label>
            <input type="email" id="email" value={email} className={styles.emailInput} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="feedback" className={styles.allLabels}>Do you have any thoughts you'd like to share</label>
            <textarea id="feedback" rows="4" placeholder="Message..." className={styles.feedbackTextarea} value={feedback} onChange={(e) => setFeedback(e.target.value)}></textarea>
          </div>
          <div className={styles.buttonGroup}>
            <button type="submit" className={styles.cancelButton} onClick={onCloseModal} >Cancel</button>
            <button type="button" className={styles.submitButton} onClick={handleSubmit}>Send</button>
          </div>
        </form>
        
        {feedbackSent && (
          <p style={{ textAlign: 'center', marginTop: '20px', color: 'green' }}>Thank you for your feedback!</p>
        )}
      </div>
    </div>
  );
};

export default FeedbackModal;
