import React, { useState } from 'react';
import { Link, useParams , useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './setpassword.module.css';
import logo from './keyicon.png';
import returnButtonImage from './ep_back.png';

const SetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { resetToken } = useParams();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    console.log("resettoekn:",resetToken)
    try {
      const response = await axios.post(`https://nodejsapp-ow9m.onrender.com/api/resetPassword/${resetToken}`, { password });
      console.log(response.data);
      navigate('/passwordSuccess');
    } catch (error) {
      console.error('Şifre sıfırlama işlemi başarısız oldu:', error.message);
      setError('Şifre sıfırlama işlemi başarısız oldu.');
    }
  };
  

  return (
    <div className={styles.container}>
      <img src={logo} alt="Logo" className={styles.logo} />
      <h1 className={styles.title}>Set your new password</h1>
      <p className={styles.subtitle}>Your new password should be different <br /> from passwords previously used</p>
        <label htmlFor="password" className={styles.labelPassword}>
          New Password
        </label>
        <input
          type="password"
          id="password"
          className={styles.inputPassword}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          style={{ fontSize: '12px' }}
        />
        <label htmlFor="confirmPassword" className={styles.labelPassword}>
          Confirm Password
        </label>
        <input
          type="password"
          id="confirmPassword"
          className={styles.inputPassword2}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          style={{ fontSize: '12px' }}
        />
        <button type="submit" className={styles.button}>Confirm</button>
        {error && <p className={styles.error}>{error}</p>}
      <div className={styles.return}>
        <Link to="/signin" className={styles.returnButton}>
          <img src={returnButtonImage} alt="Return" className={styles.returnButtonImage} />
          Return to the login screen
        </Link>
      </div>
      <p className={styles.Privacy}>Terms of Use | Privacy Policy</p>
    </div>
  );
};

export default SetPassword;
