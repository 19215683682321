import React, { useState, useEffect } from 'react';
import styles from './ChatPage.module.css';
import sendIcon from './mingcute_send-fill.png'; // Gönder butonu ikonu
import logoutIcon from './material-symbols-light_logout.png';
import logo1 from './Passific House Logo 1.png';
import logo2 from './Passific House Logo 2.png';
import userPhoto from './Group 2.png';
import FeedbackModal from './FeedbackModal';
import axios from 'axios'; // axios kütüphanesini import edin
import MarkdownIt from 'markdown-it'; // markdown-it kütüphanesini import edin
import { useNavigate } from 'react-router-dom';
import Notification from './Notification';

const md = new MarkdownIt(); // markdown-it'i kullanarak bir nesne oluşturun

const ChatPage = ({ user , setUser }) => {
  const [messages, setMessages] = useState([]);
  const [question, setQuestion] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Kullanıcı oturumu açmamışsa, giriş sayfasına yönlendir
      navigate('/signin');
    } else {
      // Kullanıcı oturum açmışsa, kullanıcı bilgilerini getir
      fetchUserData(token);
    }
  }, [navigate]);

  // Kullanıcı bilgilerini sunucudan getirme işlemi
  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('https://nodejsapp-ow9m.onrender.com/api/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const userData = response.data.userData;
        setUser(userData);
      }
    } catch (error) {
      console.error('Kullanıcı bilgilerini getirme hatası:', error);
      // Hata durumunda uygun bir mesaj döndür
      
    }
  };

  const handleFeedbackClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleQuestionSubmit = async () => {
    if (!question.trim()) {
      // Kullanıcı boş bir soru gönderdiğinde uyarı ver
      alert("Please enter a question before submitting.");
      return;
    }
  
    // Gönderilen soruyu state'e ekleyin
    setMessages([...messages, { type: "question", text: question }]);
  
    try {
      // Soruyu sunucuya gönder ve cevabı beklemeyin
      const response = await axios.post('https://nodejsapp-ow9m.onrender.com/api/generate-story', { prompt: question });
  
      // Sunucudan gelen hikayeyi Markdown formatına dönüştürün
      const storyMarkdown = response.data.story;
      const storyHTML = md.render(storyMarkdown); // markdown-it'i kullanarak Markdown'ı HTML'e dönüştürün
  
      // Sunucudan gelen cevabı Markdown formatından HTML formatına dönüştürüp state'e ekleyin
      setMessages([...messages, { type: "question", text: question },{ type: "answer", html: storyHTML }]);
  
      // Soruyu temizle
      setQuestion('');
    } catch (error) {
      console.error('Error:', error);
      // Hata durumunda uygun bir mesaj döndür
      alert('An error occurred while generating the story.');
    }
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post('https://nodejsapp-ow9m.onrender.com/api/logout');
      if (response.status === 200) {
        // Başarıyla çıkış yapıldığında bildirim göster
        setNotification({ message: 'Oturum başarıyla sonlandırıldı.', type: 'success' });
        // Token değerini localStorage'dan sil
        localStorage.removeItem('token');
        // Örneğin, kullanıcıyı giriş sayfasına yönlendirebilirsiniz
        navigate('/home');
      }
    } catch (error) {
      console.error('Oturum sonlandırma hatası:', error);
      // Hata durumunda bildirim göster
      setNotification({ message: 'Oturum sonlandırılamadı. Lütfen tekrar deneyin.', type: 'error' });
    }
  };
  

  const handleNewChat = () => {
    setMessages([]);
  }

  useEffect(() => {
    // location.state'den gelen question değerini alarak setQuestion ile state'i güncelliyoruz
    if (window.location.state && window.location.state.question) {
      setQuestion(window.location.state.question);
  
      // Gelen soruyu otomatik olarak işleme almak için handleQuestionSubmit fonksiyonunu çağırıyoruz
      handleQuestionSubmit();
    }
  }, [window.location.state, handleQuestionSubmit]);

  return (
    <div className={styles.pageContainer_chat}>
      <div className={styles.leftPanel_custom}>
        <div className={styles.leftPanelTop_custom}>
          <div className={styles.logoContainer}>
            <img src={logo1} alt="Logo" className={styles.logo1_custom} />
            <img src={logo2} alt="Second Logo" className={styles.logo2_custom} />
          </div>
          <button onClick={handleNewChat} className={styles.newChatButton_custom}>New Chat</button>
          <button onClick={handleFeedbackClick} className={styles.feedbackButton_custom}>Give Feedback</button>
        </div>
        <div className={styles.leftPanelBottom_custom}>
        <div className={styles.userInfo_custom}>
          <img src={user && user.photo ? user.photo : userPhoto} alt="User" className={styles.userPhoto_custom} />
          <span className={styles.userName_custom}>{user ? user.fullName : "Full Name"}</span>
        </div>
          <button className={styles.logoutButton_custom} onClick={handleLogout}>
            <img src={logoutIcon} alt="Send" className={styles.logoutIcon_custom} />
            Logout
          </button>
        </div>
      </div>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)} // Bildirimi kapat
        />
      )}
      <div className={styles.rightPanel_chat}>
        <div className={styles.chatScreen_custom}>
          {messages.length === 0 && <p className={styles.welcomeMessage}>How can I help you today?</p>}
          {messages.map((message, index) => (
            <div key={index} className={message.type === "question" ? styles.question_custom : styles.answer_custom}>
              <label className={styles.label_custom}>
                {message.type === "question" ? "You:" : "Adu AI:"}
              </label>
              {/* Soru tipindeki mesajları doğrudan metin olarak göster */}
              {message.type === "question" && <p>{message.text}</p>}
              {/* Cevap tipindeki mesajları HTML olarak göster */}
              {message.type === "answer" && <div dangerouslySetInnerHTML={{ __html: message.html }} />}
            </div>
          ))}
        </div>
        <div className={styles.questionInput_custom}>
          <input
            type="text"
            placeholder="Message..."
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <button className={styles.submitQuestionButton_custom} onClick={handleQuestionSubmit}>
            <img src={sendIcon} style={{ width: 45, height: 45 }} alt="Send" />
          </button>
        </div>
      </div>
      <FeedbackModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default ChatPage;
