import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // axios kütüphanesini import et
import styles from './Signin.module.css';
import googleIcon from './icons8-google-48.png'; // Google simgesi

const Signin = ({ setIsLoggedIn, setUserDetails }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignInSuccess, setIsSignInSuccess] = useState(false); // Oturum açma başarısı durumu

  const handleSignIn = async (e) => {
    e.preventDefault(); // Form submit eventinin default davranışını engelle

    try {
      const response = await axios.post('https://nodejsapp-ow9m.onrender.com/api/signin', { email, password }); // Sunucuya istek at

      if (response.status === 200) {
        // Giriş başarılı ise kullanıcıya dönen token alınmalı
        const token = response.data.token;

        const userData = response.data.user;

        // Kullanıcı bilgilerini state'e set et
        setUserDetails(userData);
        // Token'ı LocalStorage'da sakla
        localStorage.setItem('token', token);

        // Giriş başarılı olduğunu işaretle
        setIsSignInSuccess(true);

        // Ardından kullanıcıyı istenilen sayfaya yönlendirme yapılabilir.
        setIsLoggedIn(true); // setIsLoggedIn fonksiyonunu çağırarak isLoggedIn durumunu true olarak güncelle
        navigate('/chat');
      } else {
        // Giriş başarısız ise kullanıcıya hata mesajı göster
        alert('Giriş başarısız');
      }
    } catch (error) {
      console.error('Giriş hatası:', error);
      alert('Bir hata oluştu, lütfen tekrar deneyin');
    }
  };

  const signinWithGoogle = () => {
    // Kullanıcıyı Google OAuth2 servisine yönlendir
    window.location.href = 'https://nodejsapp-ow9m.onrender.com/auth/google/callback';
    
  };
  
 

  // Oturum açma başarılı olduğunda gösterilecek bildirim bileşeni
  const SignInSuccessMessage = () => {
    return (
      <div className={styles.successMessage}>
        Başarıyla oturum açtın!
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {/* Oturum açma başarısı durumuna göre bildirim göster */}
      {isSignInSuccess && <SignInSuccessMessage />}
      <h2>Sign in to Passific House</h2>
      <p className={styles.inputLabel}>Email address</p>
      <input className={styles.inputs} type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      <div className={styles.passwordLabelContainer}>
        <p className={styles.passwordLabel}>Password <a href="/forgot" className={styles.forgotLink}>Forgot?</a></p>
      </div>
      <input type="password" value={password} className={styles.Passwordinput} onChange={(e) => setPassword(e.target.value)} required />
      <button type="submit" className={styles.signinButton} onClick={handleSignIn}>Sign in</button>
      <p className={styles.SubText}>
        Don't have an account?   <a className={styles.SignUplink} href="/signup">Sign Up</a>
        <div className={styles.orContainer}>
          <div className={styles.orLine}></div>
          <div className={styles.orText}>or</div>
          <div className={styles.orLine}></div>
        </div>
      </p>
      <div className={styles.socialButtons}>
        <button className={styles.googleButton} onClick={signinWithGoogle}>
          <img src={googleIcon} alt="Google" className={styles.socialIcon} />
          Sign in with Google
        </button>
      </div>
      <p className={styles.Privacy}>Terms of Use | Privacy Policy</p>
    </div>
  );
};

export default Signin;
